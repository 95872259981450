<template>
  <div class="w-100" style="min-height: 100%">
    <div v-if="loading" class="text-center mt-5 loader mx-auto"></div>

    <div v-else class="w-100" :style="customBackground" style="min-height:100%; background-repeat: no-repeat;">
        <!-- Parametric Search -->

        <MyStoreParametricSearch/>

        <!-- Own Store Alert -->
        <div v-if="showEditBar">
            <div v-if="editMode" class="alert alert-seconary text-center">
                <span style="font-family: Rubik; font-style: normal;font-weight: 500; font-size: 18px; color: white;">You are in edit mode</span>
                <span class="ml-5">
                    <a href="#0" @click="editMode = !editMode">Click here to view</a>
                </span>
            </div>

            <div v-else class="alert alert-primary text-center" style="color: white; background-color: #7BAA64; border: 0; border-radius: 0">
                <span style="font-family: Rubik; font-style: normal;font-weight: 500; font-size: 18px; color: white;">You are viewing your store as it appears to others</span>
                <span class="ml-5" style="font-family: Rubik; font-style: normal;font-weight: 500; font-size: 18px; color: white; text-decoration:underline; ">
                    <a href="#0" @click="editMode = !editMode" style="color: white;">Click here to edit</a>
                </span>

                <span class="ml-5"><a href="#0" @click="showEditBar = false" style="color:white;">Hide this</a></span>
            </div>
        </div>

        <div class="container store-container">
            <!-- Show the store -->
            <div class="store-header row mt-5">
                <div class="col ">
                    <div><span class="h1 text-white">{{ store.name }}</span></div>
                    <div class="h2 font-weight-normal text-white">
                        <span class="c-primary">501 items</span> in <span class="c-secondary">3 games</span>
                    </div>
                </div>

                <div class="col">
                    <div class="ml-auto d-flex float-right">
                        <div class="ml-5" style="text-align: center;">
                            <img class="mt-2 img rounded-circle pin-badge" src="@/assets/badge-lightning.png" style="width: 50px; height: 50px;" alt="">
                            <div style="font-family: Rubik; font-style: normal; font-weight: 500; font-size: 12px; line-height: 28px; color: #D3D3D3;">Fast Money</div>
                        </div>
                        <div class="ml-5" style="text-align: center;">
                            <img class="mt-2 img rounded-circle pin-badge" src="@/assets/badge-trophy.png" style="width: 50px; height: 50px;" alt="">
                            <div style="font-family: Rubik; font-style: normal; font-weight: 500; font-size: 12px; line-height: 28px; color: #D3D3D3;">Top Seller</div>
                        </div>
                        <div class="ml-5" style="text-align: center;">
                            <img class="mt-2 img rounded-circle pin-badge" src="@/assets/badge-joystick.png" style="width: 50px; height: 50px;" alt="">
                            <div style="font-family: Rubik; font-style: normal; font-weight: 500; font-size: 12px; line-height: 28px; color: #D3D3D3;">Game Guru</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Store body -->
            <div class="store-featured d-flex justify-content-between mt-5">
                <Item 
                    v-for="item in store.featured" v-bind:key="item.id" 
                    :item="item" 
                    :purchasable="true" 
                    :featured="true"
                    :editable="editMode"/>
            </div>

            <!-- Switch game -->
            <div class="row mt-3">
                <div class="col">
                      <div class="h3 text-white font-weight-normal mt-4">
                            All Items For Sale <span class=" ml-3 text-white" 
                                                    v-for="game in [ 'CS:GO', 'TF2', 'RUST' ]" 
                                                    v-bind:key="game"> <span class="ml-2 mr-3 text-white"> / </span> <a :class="{ 'font-weight-bold': game == filteredGame }" :style="{ 'color': game == filteredGame ? '#ED6B33' : '' }" href="#0" @click="filterGame(game)">{{game}}</a></span>
                        </div>
                </div>

                <div v-if="editMode" class="float-right mt-4 mr-5"><button class="btn btn-primary ">Add new listing</button></div>
            </div>

            <!-- All items -->
            <div class="items-grid mt-3" style="overflow-y: auto; overflow-x: hidden;">
                <div class="d-flex " v-for="(row, i) in itemGrid" v-bind:key="i">
                    <Item class="mb-4 mr-3" 
                        :item="item" v-for="item in row" 
                        v-bind:key="item.id" 

                        :purchasable="true" 
                        :editable="editMode"
                        ></Item>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import * as mock from '../mock'
import MyStoreParametricSearch from '../components/MyStoreParametricSearch';
import defaultBackground from '@/assets/store-background.jpeg'

export default {
    data(){ 
        return {
            loading: true,
            store: {},
            customBackground: {
                'background-size': 'cover',
            },
            filteredGame: "CS:GO",
            editMode: false,
            showEditBar: true,
        }
    },
    mounted() {
        let steamId = this.$route.params.steam_id;
        let storeId = this.$route.params.store_id;

        console.log(`Attempting to load store ID: ${steamId}`);

        if (storeId) {
            // This is a store with a id
        } else if (steamId) {
            // This is a store with a steamId
        } else {
            // render a not found
        }

        // console.log(`User: ${this.user.steamData.steamid} Store: ${this.$route.params.store_id}`)
        console.log(`Debug: storeId: ${storeId} steamId: ${steamId} `)

        // TODO: Load store.
        setTimeout(() => {
            this.$set(this, 'store', mock.store())
            this.loading = false;
            
            if (this.store.background)
                this.customBackground.background = `linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, #1D2021 93.01%), url(${this.store.background}) no-repeat`
            else 
                this.customBackground.background = `black url(${defaultBackground}) no-repeat fixed center `

            console.log("Loading background " + this.customBackground.background)
            
            if (this.user.steamData && steamId == this.user.steamData.steamid) {
                this.showEditBar = true;
            }

            if (storeId == this.user.id) {
                this.showEditBar = true;
            }
       }, 500)
    },
    components: {
        MyStoreParametricSearch
    },
    computed: {
        user() {
            return this.$store.getters["auth/user"];
        },
        itemGrid() {
            // TODO: filter by game etc
            let toRender = this.store.items;

            const itemsPerRow = 4;
            // Do the 'binning' into groups of 4
            return toRender.reduce((col, item, i) => {
                if (i % itemsPerRow === 0)
                col.push([]); // Add a new row
                
                col[col.length - 1].push(item); // push item to the current row
                return col;
            }, []);
        }
    },
    methods: {
        filterGame(game) {
            this.filteredGame = game
        }
    },
    watch: {

    }
}
</script>

<style lang="scss">
.pin-badge {
    // height: 100px;
    width: 100px;

}
</style>