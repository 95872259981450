<template>
    <div>
        <div class="pt-2 pb-2" style="background-color: #1D2021">
            <div class="container" style="">
                <!-- Type -->
                <div class="navbar navbar-expand-lg" style="display: block-inline">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item mt-2 mr-3">
                                <div class="dropdown text-white">
                                    <a class="rubik" type="a" id="gameDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
                                        <span class="font-weight-light mr-2 f-rubik" style="color: #D3D3D3;">Game: </span>
                                        <span class="font-weight-bold" style="color: #D3D3D3;">Any </span> 
                                        <font-awesome-icon icon="caret-down"  style="color: #D3D3D3;"/>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="gameDropdown">
                                        <a class="dropdown-item" href="#0">CS:GO</a>
                                        <a class="dropdown-item" href="#0">Dota 2</a>
                                        <a class="dropdown-item" href="#0">Rust</a>
                                        <a class="dropdown-item" href="#0">Z1BR</a>
                                    </div>
                                </div>

                            </li>

                            <div class="divider"></div>

                            <li class="nav-item mt-2 ml-3">
                                <div class="dropdown text-white">
                                    <a class="rubik" type="a" id="dropdownMenua" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
                                        <span class="font-weight-light mr-2 f-rubik" style="color: #D3D3D3;">Sort: </span>
                                        <span class="font-weight-bold" style="color: #D3D3D3;">By Best Deal </span> 
                                        <font-awesome-icon icon="caret-down"  style="color: #D3D3D3;"/>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#0">Action</a>
                                        <a class="dropdown-item" href="#0">Another action</a>
                                        <a class="dropdown-item" href="#0">Something else here</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item mt-2">
                                <div class="dropdown text-white">
                                    <a class="rubik" type="a" id="dropdownMenua" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
                                        <span class="font-weight-light mr-2 f-rubik" style="color: #D3D3D3"><span class="mr-2"><img src="@/assets/slider.svg" alt=""></span>Sort + Filter</span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#0">Action</a>
                                        <a class="dropdown-item" href="#0">Another action</a>
                                        <a class="dropdown-item" href="#0">Something else here</a>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item">
                                <div class="">
                                    <div class="item-search input-group ">
                                        <input class="form-control g-textbox rubik" type="text" placeholder="Search This Store" style="width: 250px; background-color: #101010; border-radius:10px; border-color: #979797; color:white; border: 0">
                                        <span class="item-search-icon">
                                            <font-awesome-icon icon="search" />
                                        </span>
                                        <div></div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>